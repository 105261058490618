import { Model, Store } from 'store/Base';
import { observable, computed, action } from 'mobx';
import { User } from './User';
import { TicketStore } from './Ticket';
import { Project, ProjectStore } from './Project';
import { Casts } from './Base';

export function combineDateAndTime(dateValue, timeValue) {
    return dateValue.clone().set({
       hour: timeValue.hour(),
       minute: timeValue.minute(),
       second: timeValue.second(),
       millisecond: timeValue.millisecond(),
    });
}

export class TimeRegistration extends Model {
    static backendResourceName = 'time_registration';
    static omitFields = ['oldRealtimeId',  'isAltered'];

    @observable id = null;
    @observable startedAt = null;
    @observable endedAt = null;
    @observable description = '';
    @observable oldRealtimeId: null | number = null;

    // Very experimental, gives an educated guess, but is not fully trustworthy
    @observable hasActiveBudget = false;

    @observable isAltered = false

    fixEndDate(endedAt) {
        // So with endedAt we actually only care about the time, this function
        // changes the value in such a way that it is always the first instance
        // of that time that occurs after startedAt
        if (this.startedAt === null || endedAt === null) {
            return endedAt;
        }

        const fixedEndedAt = combineDateAndTime(this.startedAt, endedAt);

        // if its the before we assume it was ment as this time on the next day
        if (fixedEndedAt.isBefore(this.startedAt)) {
            fixedEndedAt.add(1, 'day');
        }

        // if the fixedEndedAt is actually not different from the endedAt we
        // just return endedAt instead, this makes it easier to detect if we
        // actually have changes
        if (fixedEndedAt.isSame(endedAt)) {
            return endedAt;
        } else {
            return fixedEndedAt;
        }
    }

    @action setInput(name, value) {
        // we make sure that the date of endedAt always is in line with that of
        // startedAt
        switch (name) {
            case 'startedAt':
                if (value) {
                    value = value.clone().startOf('minute');
                }
                super.setInput('startedAt', value);
                // eslint-disable-next-line no-case-declarations
                const endedAt = this.fixEndDate(this.endedAt);
                if (endedAt !== this.endedAt) {
                    super.setInput('endedAt', endedAt);
                }
                break;

            case 'endedAt':
                if (value) {
                    value = value.clone().startOf('minute');
                }
                super.setInput('endedAt', this.fixEndDate(value));
                break;

            default:
                return super.setInput(name, value);
        }
    }

    @observable user = this.relation(User)
    @observable projects = this.relation(ProjectStore)
    @observable tickets = this.relation(TicketStore)

    casts() {
        return {
            startedAt: Casts.datetime,
            endedAt: Casts.nullableDatetime,
        };
    }

    @computed get isCompleted() {
        return this.endedAt !== null;
    }

    @computed get duration() {
        if (this.startedAt === null) {
            return null;
        }
        // if there is no ended at we assume its going on atm
        // @ts-ignore
        const endedAt = this.endedAt ?? window.viewStore.currentMinute;
        const duration = endedAt.diff(this.startedAt, 's', true);
        return Math.max(duration, 0);
    }
}

export class TimeRegistrationStore extends Store {
    Model = TimeRegistration;
    static backendResourceName = 'time_registration';

    async addProjects(projects: Project[]) {
        return await this.api.post(
            'time_registration/add_projects/',
            {
                'time_registration_ids': this.models.map(model => model.id),
                'project_ids': projects.map(project => project.id)
            }
        )
    }

    async deleteProjects(projects: Project[]) {
        return await this.api.post(
            'time_registration/delete_projects/',
            {
                'time_registration_ids': this.models.map(model => model.id),
                'project_ids': projects.map(project => project.id)
            }
        )
    }
}
