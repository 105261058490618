import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { User } from './User';
import { Project } from './Project';

export const TEAM_MEMBER_ROLE_DEV = 'dev';
export const TEAM_MEMBER_ROLE_UX = 'UX';

export const TEAM_MEMBER_ROLES = [
    TEAM_MEMBER_ROLE_DEV,
    TEAM_MEMBER_ROLE_UX
]

export class ProjectTeamMember extends Model {
    static backendResourceName = 'project_team_member';


    @observable id = null;

    @observable project = this.relation(Project);
    @observable user = this.relation(User)
    @observable role = TEAM_MEMBER_ROLE_DEV

}

export class ProjectTeamMemberStore extends Store {
    Model = ProjectTeamMember;
    static backendResourceName = 'project_team_member';
}
