import { configureTranslations, t } from 'i18n';
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import App from './container/App';
import { ViewStore } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import { observable, action, reaction } from 'mobx';
import moment from 'moment';
import { theme } from 'styles';
import { injectGlobal } from 'styled-components';



// use router instead of browserRouter for sentry, see
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381
import { Router } from 'react-router-dom';
import { ReCyCleTheme } from 're-cy-cle';
import * as Sentry from '@sentry/react';
import configureSentry, { setUserSentry } from './sentry';
import { PUBLIC_URL, SERVER_DATETIME_FORMAT, configOverride } from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureNotification } from '@code-yellow/spider';
import { configurePermission } from '@code-yellow/spider';
import { configureCurrentUser } from 'helpers/currentUser';
import { CancelButton, ApplyButton  } from '@code-yellow/spider';
import { configureBasename, configureAfterSave  } from '@code-yellow/spider';
import { Modal } from 'semantic-ui-react';
import { RightDivider } from '@code-yellow/spider';
import 'moment-duration-format';
import { api } from 'store/Base';
import { User } from 'store/User';
import { configureTranslation as configureDayCYTranslation } from 'daycy';
import { createBrowserHistory } from 'history';
import { configureModules } from '_iae/module/repository';
import { systemModule } from './_iae/system'
import { spiderModule, configureApi } from '@code-yellow/spider';



import 'daycy/dist/daycy.css';
import 'style/semantic-ui/foo/bar/main.css';
import 'style/semantic-ui/daycy.css';
import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';
import '@material-design-icons/font';
import 'style/material-design-icons/icons.css';
import i18next from 'i18next';
import { TimeRegistration, TimeRegistrationStore } from 'store/TimeRegistration';


injectGlobal`
    aside {
        background-color: ${theme.sidebarColor} !important;
    }
`;


configureApi(api);
const moduleRepository = configureModules([
    systemModule,
    spiderModule
])
configureTranslations(moduleRepository);


configureDayCYTranslation((key, args) => {
    return t(`daycy.${key}`, args);
});

export class SpecialViewStore extends ViewStore {
    constructor(...args) {
        super(...args);

        this.checkCurrentTimeRegistrationUpdate = this.checkCurrentTimeRegistrationUpdate.bind(this);
        this.checkCurrentTimeRegistrationDelete = this.checkCurrentTimeRegistrationDelete.bind(this);

        this.workingReaction = reaction(
            () => this.currentTimeRegistration.startedAt !== null && this.currentTimeRegistration.endedAt === null,
            (working) => {
                const favicon = document.querySelector('html > head > link[rel="icon"]');
                const prefix = favicon.href.slice(0, favicon.href.lastIndexOf('/'));
                const icon = working ? 'favicon-working.svg' : 'favicon-not-working.svg';
                favicon.href = `${prefix}/${icon}`;
            },
            { fireImmediately: true },
        );

        this.minuteInterval = setInterval(() => {
            const currentMinute = moment().startOf('minute');
            if (!currentMinute.isSame(this.currentMinute)) {
                this.currentMinute = currentMinute;
            }
        }, 1000);
    }

    @observable phabricatorUrl = null;

    currentTimeRegistration = new TimeRegistration({}, { relations: ['user', 'projects', 'tickets'] });
    lastTimeRegistration = new TimeRegistration();
    activeCurrentTimeRegistrationForms = 0;

    @observable currentMinute = moment().startOf('minute');

    async fetchCurrentTimeRegistration() {
        const store = new TimeRegistrationStore({
            relations: ['projects', 'tickets'],
            limit: 1,
            params: {
                '.user.id': this.currentTimeRegistration.user.id.toString(),
                '.ended_at:isnull': 'true',
                '.old_realtime_id:isnull': 'true',
            },
        });
        await store.fetch();
        if (store.models.length !== 0) {
            this.currentTimeRegistration.parse(store.models[0].toJS());
        }
    }

    async fetchLastTimeRegistration() {
        const store = new TimeRegistrationStore({
            limit: 1,
            params: {
                '.user.id': this.currentTimeRegistration.user.id.toString(),
                '.ended_at:isnull': 'false',
                '.old_realtime_id:isnull': 'true',
                order_by: '-ended_at',
            },
        });
        await store.fetch();
        if (store.models.length !== 0) {
            this.lastTimeRegistration.parse(store.models[0].toJS());
        } else {
            this.lastTimeRegistration.clear();
        }
    }

    @action checkCurrentTimeRegistrationUpdate({ data }) {
        if (data.id === this.currentTimeRegistration.id || (data.ended_at === null && data.old_realtime_id === null)) {
            if (data.ended_at !== null && this.activeCurrentTimeRegistrationForms === 0) {
                this.currentTimeRegistration.clear();
                this.currentTimeRegistration.user.parse(this.currentUser.toJS());
            } else {
                this.currentTimeRegistration.parse(data);
            }
        }

        if (data.id === this.lastTimeRegistration.id) {
            if (this.lastTimeRegistration.endedAt.isSameOrBefore(moment(data.ended_at, SERVER_DATETIME_FORMAT))) {
                // ended at stayed the same or became later so this is still the last
                this.lastTimeRegistration.parse(data);
            } else {
                // it became earlier so we should check if another entry is now later
                this.fetchLastTimeRegistration();
            }
        } else if (data.old_realtime_id === null && data.ended_at !== null && (
            this.lastTimeRegistration.isNew ||
            this.lastTimeRegistration.endedAt.isBefore(moment(data.ended_at, SERVER_DATETIME_FORMAT))
        )) {
            // this entry is later
            this.lastTimeRegistration.parse(data);
        }
    }

    @action checkCurrentTimeRegistrationDelete({ data }) {
        if (data.id === this.currentTimeRegistration.id) {
            this.currentTimeRegistration.clear();
            this.currentTimeRegistration.user.parse(this.currentUser.toJS());
        }

        if (data.id === this.lastTimeRegistration.id) {
            this.lastTimeRegistration.clear();
            this.fetchLastTimeRegistration();
        }
    }

    @action handleBootstrap(res) {
        if (res.user === null && res.phabricator_auth_enabled) {
            window.location = `/api/phabricator/login/?next_url=${encodeURIComponent(window.location.pathname + window.location.search)}`;
            return;
        }

        this.phabricatorUrl = res.phabricator_url;
        this.currentTimeRegistration.clear();

        configOverride(res);
        if (res.language) {
            i18next.changeLanguage(res.language);
        }
        return super.handleBootstrap(res);
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
            data: res.user.data,
            repos: res.user.with,
            relMapping: res.user.with_mapping,
        });
        setUserSentry(this.currentUser)

        this.currentTimeRegistration.user.parse(this.currentUser.toJS());
        this.fetchCurrentTimeRegistration();
        this.fetchLastTimeRegistration();
    }

    setupSocket() {
        super.setupSocket();

        if (!this.api.socket) {
            return;
        }

        this.api.socket.subscribe({
            room: { target: 'time-registration-create', user: this.currentUser.id },
            onPublish: this.checkCurrentTimeRegistrationUpdate,
        });
        this.api.socket.subscribe({
            room: { target: 'time-registration-update', user: this.currentUser.id },
            onPublish: this.checkCurrentTimeRegistrationUpdate,
        });
        this.api.socket.subscribe({
            room: { target: 'time-registration-delete', user: this.currentUser.id },
            onPublish: this.checkCurrentTimeRegistrationDelete,
        });
    }
};


const viewStore = new SpecialViewStore({
	api,
	user: new User(null, {
        relations: ['groups.permissions']
    }),
	socketUrl: `${PUBLIC_URL || ''}/ws/`
});



/**
 * Currently test newAppVersionNotification abuses the fact that viewStore is
 * globally available. We should only expose this when debug = true. BOEK has
 * a debug mode, where you can see more in the interface (like calculations)
 * and have access to scary buttons.
 */
window.viewStore = viewStore;

export const history = createBrowserHistory();
configureSentry(viewStore, history);

configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({ goBack: false, createUrl: '/add' });
configureCurrentUser(viewStore);

@observer
class Root extends Component {
    @observable showAlert = false;
    @observable alertMessage = '';
    @observable alertConfirm = null;

    // Custom alert callbacks.
    @observable alertOnApply = null;
    @observable alertOnCancel = null;

    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    cancel = () => {
        this.showAlert = false;

        if (this.alertOnCancel) {
            this.alertOnCancel();
        }

        this.alertConfirm(false);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    confirm = () => {
        this.showAlert = false;

        if (this.alertOnApply) {
            this.alertOnApply();
        }

        this.alertConfirm(true);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    render() {
        return (
            <Sentry.ErrorBoundary showDialog>
                <React.Fragment key={i18next.language}>
                <Modal size="tiny" open={this.showAlert} centered={false}>
                    <Modal.Content style={{ textAlign: 'center' }}>
                        <p>{this.alertMessage}</p>
                        <p>{t('form.confirmQuestion')}</p>
                    </Modal.Content>
                    <Modal.Actions style={{ display: 'flex' }}>
                        <CancelButton negative onClick={this.cancel} />
                        <RightDivider />
                        <ApplyButton positive onClick={this.confirm} content={t('form.continueButton')} />
                    </Modal.Actions>
                </Modal>
                <ReCyCleTheme theme={theme}>
                    <Router history={history} basename={PUBLIC_URL} getUserConfirmation={(message, confirm, ...args) => {
                        this.showAlert = true;
                        this.alertConfirm = confirm;

                        if (typeof message === 'object') {
                            this.alertMessage = message.message;
                            this.alertOnApply = message.onApply;
                            this.alertOnCancel = message.onCancel;
                        } else {
                            this.alertMessage = message;
                            this.alertOnApply = null;
                            this.alertOnCancel = null;
                        }

                    }}>
                        <App moduleRepository={moduleRepository} store={viewStore} />
                    </Router>
                </ReCyCleTheme>
            </React.Fragment>
        </Sentry.ErrorBoundary>
        );
    }
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Root />, container);
