import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Contact } from './Contact';


export class Company extends Model {
    static backendResourceName = 'company';
    static omitFields = ['createdAt']

    @observable id = null;
    @observable name = '';
    @observable shortName = null;

    @observable createdAt = '';

    @observable qaEmail = '';

    @observable communicationRhythm = '';

    @observable invoiceAgreement = '';



    relations() {
        return {
            mainContact: Contact,
            responsible: Contact,
        }
    }

}

export class CompanyStore extends Store {
    Model = Company;
    static backendResourceName = 'company';
}
