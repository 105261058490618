import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Contact } from './Contact';
import { TicketRelationStore } from './TicketRelation';

export class Ticket extends Model {
    static backendResourceName = 'ticket';
    static omitFields = ['fullName', 'timeSpent']

    @observable id = null;
    @observable number = 0;
    @observable title = '';

    // T1234 - FOO BAR (annotation on backend)
    @observable fullName = ''


    // Timespent on the ticket in seconds
    @observable timeSpent = 0;

    @observable assignedTo = this.relation(Contact);

    @observable parentRelations = this.relation(TicketRelationStore);

    @observable childRelations = this.relation(TicketRelationStore);
}

export class TicketStore extends Store {
    Model = Ticket;
    static backendResourceName = 'ticket';
}
