import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled, { css } from 'styled-components';
import { opacify } from 'polished';
import { Button, TopMenu, MenuRow as BaseMenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { IconButton } from '@code-yellow/spider';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo_text.svg';
import { SmallAvatar } from 'component/UserAvatar';
import { CurrentTimeRegistrationForm } from 'component/TimeRegistration/Form';
import { BUILD_INFO, TIME_FORMAT } from 'helpers';
import { ModuleRepository } from '_iae/module/repository';

const MyLogo = () => (
    <Logo>
        <img src={ImgLogo} height="25" alt="logo"/>
    </Logo>
);

const MenuRow = styled(BaseMenuRow)`
    background-color: ${({ theme }) => theme.primaryColor};
    color: #FFF;

    .nav-item:before {
        border-bottom-color: ${({ theme }) => theme.toolbarColor};
    }

    &:nth-child(even) {
        background-color: ${({ theme }) => theme.toolbarColor}
        color: ${({ theme }) => theme.primaryColor};
    }
`;

const Stack = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin-left: 0.25em;
    height: 100%;
    justify-content: stretch;
`;

const CurrentUserContainer = styled.div`;
    flex: 1 1 0;
    line-height: 1;

    display: flex;
    align-items: flex-end;
`

const WorkingStatusContainer = styled.div`
    flex: 1 1 0;

    display: flex;
    align-items: flex-start;

    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8em;
    line-height: 1.5;

    > i.icon {
        font-size: 0.5em;
        height: 3em;
        line-height: 3em;
        ${({ working }) => working && css`
            color: #21ba45;
        `}
    }
`;

const WorkingEditContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-left: 20em;
    padding-right: 1em;
    z-index: 9999;
    background: ${({ theme }) => {
        const bgColor = opacify(-0.1, theme.primaryColor);
        return `linear-gradient(to right, transparent 0, ${bgColor} 20em, ${bgColor} 100%)`;
    }};

    display: flex;
    align-items: center;

    ${({ open }) => open ? css`
        transform: translateX(0);
        opacity: 1;
    ` : css`
        pointer-events: none;
        transform: translateX(40em);
        opacity: 0;
    `}
    transition: transform 600ms ease, opacity 600ms ease;

    font-size: 0.8rem;

    > form.ui.form {
        font-size: inherit;
        > .fields {
            margin-bottom: 0;
            > .field > .ui.dropdown {
                white-space: nowrap;
            }
            > .ui.button {
                font-size: 1em;
            }
        }
    }

    > i.icon {
        font-size: 1.5em;
        line-height: 1;
        margin: 0 0 0 0.25em !important;
        color: rgba(255, 255, 255, 0.5);
    }
`;

function stopPropagation(e) {
    e.stopPropagation();
}

@observer
class WorkingStatus extends Component {
    static propTypes = {
        appHeader: PropTypes.object.isRequired,
    };

    render() {
        const { appHeader } = this.props;

        const timeRegistration = window.viewStore.currentTimeRegistration;

        const working = timeRegistration.startedAt !== null && timeRegistration.endedAt === null;

        let text;
        if (working) {
            let subject = [];
            // eslint-disable-next-line no-unused-vars
            for (const project of timeRegistration.projects.models) {
                subject.push(project.fullName);
            }
            // eslint-disable-next-line no-unused-vars
            for (const ticket of timeRegistration.tickets.models) {
                subject.push(`T${ticket.number}`);
            }
            if (subject.length === 0) {
                subject = t('workingStatus.unspecified');
            } else {
                subject = subject.join(', ');
            }

            const startedAt = timeRegistration.startedAt.format(TIME_FORMAT);

            text = t('workingStatus.working', { subject, startedAt });
        } else {
            text = t('workingStatus.notWorking');
        }

        return (
            <WorkingStatusContainer data-test-working-status working={working} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                appHeader.workingEditOpen = true
            }}>
                <Icon name="circle" />
                {text}
            </WorkingStatusContainer>
        );
    }
}

@observer
class WorkingEdit extends Component {
    static propTypes = {
        appHeader: PropTypes.object.isRequired,
    }

    render() {
        const { appHeader } = this.props;
        return (
            <WorkingEditContainer data-test-working-edit open={appHeader.workingEditOpen} onClick={stopPropagation} onMouseDown={stopPropagation} onMouseUp={stopPropagation}>
                <CurrentTimeRegistrationForm noLabel open={appHeader.workingEditOpen} />
                <IconButton name="close" onClick={() => appHeader.workingEditOpen = false} />
            </WorkingEditContainer>
        );
    }
}

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderIso = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.iso.documents.title')}
                    to="/iso/document/document/overview"
                    activePath="/iso/document/"
                />
                <NavItem
                    title={t('nav.iso.checks')}
                    to="/iso/checks/no-mfa"
                    activePath="/iso/checks/"
                />
            </NavMenu>
        )
    }

    renderAssets = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
                <NavItem
                    title={t('nav.assets.globalValues')}
                    to="/assets/global-value/overview"
                    activePath="/assets/global-value/"
                />
            </NavMenu>
        );
    };

    renderAudit = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.audit.accessLog')}
                    to="/audit/access-log/overview"
                    activePath="/audit/access-log/"
                />
            </NavMenu>
        );
    }


    renderCrm = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.crm.contracts')}
                    to="/crm/contract/overview"
                    activePath="/crm/contract/"
                />
                <NavItem
                    title={t('nav.crm.contacts')}
                    to="/crm/contact/overview"
                    activePath="/crm/contact/"
                />
                <NavItem
                    title={t('nav.crm.companies')}
                    to="/crm/company/overview"
                    activePath="/crm/company/"
                />
                <NavItem
                    title={t('nav.crm.teams')}
                    to="/crm/team/overview"
                    activePath="/crm/team/"
                />
                <NavItem
                    title={t('nav.crm.entities')}
                    to="/crm/entity/overview"
                    activePath="/crm/entity/"
                />
            </NavMenu>
        );
    }

    renderOperations = () => {
        const { currentUser } = this.props.store;
        return (
            <NavMenu>
                <NavItem
                    title="Projects"
                    to={'/operations/operations/overview/fixed_fee'}
                />
                <NavItem
                    title="Support"
                    to={'/operations/operations/overview/support'}
                />
                <NavItem
                    title="Unlinked"
                    to={'/operations/operations/overview/unpaid'}
                />
                <NavItem
                    title={t('nav.operations.milestones')}
                    to="/operations/milestone/overview"
                    activePath="/operations/milestone/"
                />
                <NavItem
                    title={t('nav.operations.project')}
                    to="/operations/project/overview"
                    activePath="/operations/project"
                />
                {currentUser.inGroup('experimental') && (
                    <NavItem
                        title={t('nav.operations.userSchedule')}
                        to="/operations/user-schedule/overview"
                        activePath="/operations/project"
                    />
                )}
                <NavItem
                    title={t('nav.operations.tickets')}
                    to="/operations/ticket/overview"
                    activePath="/operations/ticket"
                />
                <NavItem
                    title={t('nav.operations.tags')}
                    to="/operations/tag/overview"
                    activePath="/operations/tag"
                />
            </NavMenu>
        );
    }

    renderTimeRegistration = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.timeRegistration.myTime')}
                    to="/time-registration/my-time"
                    activePath="/time-registration/my-time"
                />
                <NavItem
                    title={t('nav.timeRegistration.overview')}
                    to="/time-registration/overview"
                    activePath="/time-registration/overview"
                />
            </NavMenu>
        )
    }

    @observable workingEditOpen = false;

    renderAccountMenu = () => {
        const { store } = this.props;
        const { version, branch } = BUILD_INFO;

        return (
            <>
                <NavItem
                    title={
                        <>
                            <SmallAvatar user={store.currentUser}/>
                            <Stack>
                                <CurrentUserContainer data-test-current-user>
                                    {store.currentUser.fullName} (
                                    {branch && branch !== 'production' && branch + ' '}
                                    {version}
                                    )
                                </CurrentUserContainer>
                                <WorkingStatus appHeader={this} />
                            </Stack>
                        </>
                    }
                    to="/account/details"
                    activePath="/account/"
                />
                <WorkingEdit appHeader={this} />
            </>
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                </React.Fragment>
            </NavMenu>
        );
    };

    renderInfra = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.infra.server')}
                    to="/infra/server/overview"
                />
            </NavMenu>
        );
    }

    renderRoadmap = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.modules.overview')}
                    to="/roadmap/module/overview"
                />
                <NavItem
                    title={t('nav.modules.whale.overview')}
                    to="/roadmap/whale/overview"
                />
                <NavItem
                    title={'Float'}
                    to="/roadmap/float/overview"
                />
                <NavItem
                    title={'Whale prototype'}
                    to="/roadmap/whale/prototype"
                />
            </NavMenu>
        );
    }

    render() {
        const { currentUser } = this.props.store;
        const { moduleRepository } = this.props;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <MenuRow>
                        <MyLogo/>
                    </MenuRow>
                    <MenuRow/>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = currentUser.isSuperuser ? (
            <TopMenu>
                <MenuRow style={{ position: 'relative' }}>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.assets.users')}
                            to="/assets/user/overview"
                            activePath="/assets/user/"
                        />
                        <NavItem
                            title={t('nav.audit.accessLog')}
                            to="/audit/access-log/overview"
                            activePath="/audit/access-log/"
                        />
                        {moduleRepository.modules.map(module => {
                            return module.navigationMenu?.()?.topMenuItem;
                        })}
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    {/* Hackity hack hack to always show second menu bar */}
                    <NavItem title=" " to="/" activePath="/neverusethispath"/>
                </MenuRow>
            </TopMenu>
        ) : (
            <TopMenu>
                <MenuRow style={{ position: 'relative' }}>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.main.timeRegistration')}
                            to="/time-registration/my-time"
                            activePath="/time-registration/"
                        />
                        {currentUser.inGroup('experimental') && (
                            <NavItem
                                title={t('nav.main.roadmap')}
                                to={'/roadmap/module/overview'}
                                activePath="/roadmap"
                            />
                        )}

                        <NavItem
                            title={t('nav.main.operations')}
                            to="/operations/milestone/overview"
                            activePath="/operations/"
                        />
                        <NavItem
                            title={t('nav.main.crm')}
                            to="/crm/company/overview"
                            activePath="/crm/"
                        />
                        <NavItem
                            title={t('nav.main.infra')}
                            to="/infra/server/overview"
                            activePath="/infra/"
                        />
                        <NavItem
                            title={t('nav.main.assets')}
                            to="/assets/user/overview"
                            activePath="/assets/"
                        />
                        {currentUser.inGroup('iso') && (
                            <NavItem
                                title={t('nav.main.iso')}
                                to="/iso/document/document/overview"
                                activePath="/iso/"
                            />
                        )}
                        <NavItem
                            title={t('nav.main.docs')}
                            as={'a'}
                            to="#"
                            activePath="/docs/"
                            onClick={() => {
                                window.open('http://docs.internal/')
                            }}
                        />
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    <Route path="/assets/" render={this.renderAssets}/>
                    <Route path="/audit/" render={this.renderAudit}/>
                    <Route path="/operations/" render={this.renderOperations}/>
                    <Route path="/infra/" render={this.renderInfra}/>
                    <Route path="/crm/" render={this.renderCrm}/>
                    <Route path="/time-registration/" render={this.renderTimeRegistration}/>
                    <Route path="/roadmap/" render={this.renderRoadmap}/>
                    <Route path="/iso/" render={this.renderIso} />
                </MenuRow>
            </TopMenu>
        );

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
