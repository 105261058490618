import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Heading, Subheading } from 're-cy-cle';
import FullScreenOverlay from 'component/FullScreenOverlay';
import { t } from 'i18next';

interface StartupErrorProps {
    code: number
}
@observer
export default class StartupError extends Component<StartupErrorProps> {
    render() {
        return (
            <FullScreenOverlay>
                <Heading>Server error ({this.props.code})</Heading>
                <Subheading>
                    {this.props.code === 502 ? (
                        <span>
                            {t('startError.maintenance')}
                        </span>
                    ) : (
                        <span>
                            {t('startError.line1')}
                            <br/>
                            {t('startError.line2')}
                        </span>
                    )}
                </Subheading>
            </FullScreenOverlay>
        );
    }
}
