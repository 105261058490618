// Hacky helper to ease working with modals.
import { SpecialViewStore } from '../index';
import { Modal } from 'semantic-ui-react';

let viewStore: SpecialViewStore | null;

export function configureModal(givenViewStore) {
    viewStore = givenViewStore;
}

export default function show(ModalInstance: typeof Modal, props = {}) {
    viewStore?.setModal({
        render: ModalInstance,
        ...props,
    });
}
