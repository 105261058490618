import { tint } from 'polished';

const primaryColor = '#2D3046';
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = '#2D303B';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';
const toolbarColor = '#EAECEF';
const sidebarColor = '#F8FAFC';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
    toolbarColor,
    sidebarColor,
};
