import { Model, Store } from 'store/Base';
import { Project } from './Project';
import { observable } from 'mobx';
import { Module } from './Module';


export class ProjectModule extends Model {
    static backendResourceName = 'project_module';

    @observable id = null;
    @observable project = this.relation(Project);
    @observable module = this.relation(Module);
}

export class ProjectModuleStore extends Store {
    Model = ProjectModule;
    static backendResourceName = 'project_module';
}
