import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observable, computed, action, reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { TimeRegistration, combineDateAndTime } from 'store/TimeRegistration';
import { combinedStore } from 'store/Base';
import { Project } from 'store/Project';
import { Ticket } from 'store/Ticket';
import { showSaveNotification } from '@code-yellow/spider';
import { TargetTextInput, TargetSelect, TargetDatePicker, TargetTimePicker, momentToLuxon, luxonToMoment } from '@code-yellow/spider';
import { Form, Button, Icon, Label } from 'semantic-ui-react';
import { DATE_FORMAT, TIME_FORMAT } from 'helpers';
import styled from 'styled-components';

const CenterIconButton = styled(Button)`
    align-self: flex-end;
    height: calc(1px * 2 + 0.67857143em * 2 + 1.21428571em) !important;
    margin: 0 0.5em !important;
`;

function momentToLuxonNullSafe(value) {
    if (value === null) {
        return null;
    }
    return momentToLuxon(value);
}

function luxonToMomentNullSafe(value) {
    if (value === null) {
        return null;
    }
    return luxonToMoment(value);
}

const ProjectOrTicketStore = combinedStore(Project, Ticket);

const ViewIcon = styled(Icon)`
    margin-left: 0.25em !important;
    margin-right: 0 !important;
    color: rgba(0, 0, 0, 0.6) !important;
    &:hover {
        color: rgba(0, 0, 0, 0.8) !important;
    }
`;

@observer
export class TicketLabel extends Component {
    static propTypes = {
        number: PropTypes.number.isRequired,
    };

    onClick(e) {
        e.stopPropagation();
    }

    render() {
        const { number } = this.props;
        return (
            <>
                T{number}
                <a target="_blank" href={`https://phabricator.codeyellow.nl/T${number}`}>
                    <ViewIcon name="eye" onClick={this.onClick} />
                </a>
            </>
        );
    }
}

@observer
export class ProjectsOrTicketsSelect extends Component {
    static propTypes = {
        timeRegistration: PropTypes.instanceOf(TimeRegistration).isRequired,
    };

    constructor(...args) {
        super(...args);
        this.setProjectsOrTickets = this.setProjectsOrTickets.bind(this);
    }

    projectOrTicketStore = new ProjectOrTicketStore({
        relations: ['project', 'ticket'],
        params: { order_by: 'model_index' },
    });

    @computed get projectsOrTickets() {
        const { timeRegistration } = this.props;
        const store = new ProjectOrTicketStore({
            relations: ['project', 'ticket'],
        });

        // eslint-disable-next-line no-unused-vars
        for (const project of timeRegistration.projects.models) {
            store.add({
                id: ProjectOrTicketStore.getId(project),
                project: project.toJS(),
            });
        }
        // eslint-disable-next-line no-unused-vars
        for (const ticket of timeRegistration.tickets.models) {
            store.add({
                id: ProjectOrTicketStore.getId(ticket),
                ticket: ticket.toJS(),
            });
        }

        return store;
    }

    @action setProjectsOrTickets(ids) {
        const { timeRegistration } = this.props;

        const projects = [];
        const tickets = [];

        // eslint-disable-next-line no-unused-vars
        for (const id of ids) {
            const model = this.projectOrTicketStore.get(id) ?? this.projectsOrTickets.get(id);
            if (!model.project.isNew) {
                projects.push(model.project);
            } else {
                tickets.push(model.ticket);
            }
        }

        timeRegistration.setInput('projects', projects);
        timeRegistration.setInput('tickets', tickets);
    }

    render() {
        const { timeRegistration, ...props } = this.props;
        return (
            <TargetSelect search remote multiple skipFetch
                target={timeRegistration}
                name="projectsOrTickets"
                value={this.projectsOrTickets}
                onChange={this.setProjectsOrTickets}
                store={this.projectOrTicketStore}
                fromTarget={(store) => store.map((model) => model.id)}
                toOption={projectOrTicket => ({
                    value: projectOrTicket.id,
                    text: (
                        projectOrTicket.project.isNew
                        ? projectOrTicket.ticket.fullName
                        : projectOrTicket.project.fullName
                    ),
                })}
                renderLabel={({ value, ...props }) => {
                    const projectOrTicket = this.projectsOrTickets.get(value);
                    if (!projectOrTicket) {
                        return null;
                    } else if (projectOrTicket.project.isNew) {
                        props.content = <TicketLabel number={projectOrTicket.ticket.number} />;
                    } else {
                        props.content = projectOrTicket.project.fullName;
                    }
                    return <Label {...props} />;
                }}
                {...props}
            />
        );
    }
}

@observer
export default class TimeRegistrationForm extends Component {
    static propTypes = {
        timeRegistration: PropTypes.instanceOf(TimeRegistration).isRequired,
        autosave: PropTypes.bool,
        group: PropTypes.bool,
        noLabel: PropTypes.bool,
        children: PropTypes.node,
        startPlaceholder: PropTypes.object,
        endPlaceholder: PropTypes.object,
    };

    static defaultProps = {
        autosave: false,
        group: false,
        noLabel: false,
        children: null,
        startPlaceholder: null,
        endPlaceholder: null,
    }

    constructor(...args) {
        super(...args);
        this.setDate = this.setDate.bind(this);
        this.setStartedAt = this.setStartedAt.bind(this);
        this.onProjectsOrTicketsKeyDown = this.onProjectsOrTicketsKeyDown.bind(this);
    }

    save = async () => {
        const { timeRegistration } = this.props;
        // _saveAll so that we do not process the response data, we do not want
        // this since the websocket data tends to come earlier than the
        // response data and it can be that based on that we decide to clear
        // the time registration and start a new one
        await timeRegistration.api[timeRegistration.isNew ? 'post' : 'patch'](
            timeRegistration.url,
            timeRegistration.toBackend({ onlyChanges: true }),
        );
        showSaveNotification();
    }

    autosaveIfDescriptionChanged = () => {
        const { timeRegistration } = this.props;
        if (timeRegistration.__changes.includes('description')) {
            this.autosave();
        }
    };

    autosave = () => {
        const { timeRegistration, autosave } = this.props;
        if (autosave && timeRegistration.startedAt !== null) {
            this.save();
        }
    };

    @observable startDate = null;

    @action setDate(startedAt) {
        const { timeRegistration } = this.props;

        if (startedAt !== null && timeRegistration.startedAt === null) {
            this.startDate = startedAt;
            const form = ReactDOM.findDOMNode(this);
            const timeInput = form.querySelector('.time-picker > input[name="startedAt"]');
            timeInput.select();
        } else {
            timeRegistration.setInput('startedAt', startedAt);
            this.autosave();
        }
    }

    @action setStartedAt(startedAt) {
        const { timeRegistration } = this.props;

        if (startedAt !== null && timeRegistration.endedAt === null) {
            const form = ReactDOM.findDOMNode(this);
            const endInput = form.querySelector('input[name="endedAt"]');
            endInput.select();
        }

        if (this.startDate && startedAt !== null) {
            startedAt = combineDateAndTime(this.startDate, startedAt);
            this.startDate = null;
        }
        timeRegistration.setInput('startedAt', startedAt);
        this.autosave();
    }

    onProjectsOrTicketsKeyDown(e) {
        if (e.key === 'Tab' && !e.shiftKey && !e.ctrlKey && !e.metaKey) {
            e.preventDefault();
            e.stopPropagation();
            const form = ReactDOM.findDOMNode(this);
            const descriptionInput = form.querySelector('input[name="description"]');
            descriptionInput.select();
        }
    }

    render() {
        const { timeRegistration, group, noLabel, children, startPlaceholder, endPlaceholder } = this.props;

        let fields = (
            <>
                <ProjectsOrTicketsSelect
                    noLabel={noLabel}
                    placeholder={noLabel ? t('timeRegistration.field.projectsOrTickets.label') : undefined}
                    timeRegistration={timeRegistration}
                    afterChange={this.autosave}
                    autoComplete="off"
                    onKeyDown={this.onProjectsOrTicketsKeyDown}
                />
                <TargetTextInput
                    noLabel={noLabel}
                    placeholder={noLabel ? t('timeRegistration.field.description.label') : (timeRegistration.tickets?.length > 0 && timeRegistration.tickets.at(0).title)}
                    target={timeRegistration}
                    name="description"
                    onBlur={this.autosaveIfDescriptionChanged}
                    autoComplete="off"
                />
                <TargetDatePicker includeWeeks
                    noLabel={noLabel}
                    placeholder={startPlaceholder?.format(DATE_FORMAT) ?? (noLabel ? t('timeRegistration.field.date.label') : undefined)}
                    label={t('timeRegistration.field.date.label')}
                    target={timeRegistration}
                    name="startedAt"
                    fromTarget={momentToLuxonNullSafe}
                    toTarget={luxonToMomentNullSafe}
                    value={timeRegistration.startedAt ?? this.startDate}
                    onChange={this.setDate}
                    autoComplete="off"
                />
                <TargetTimePicker noPopup
                    noLabel={noLabel}
                    placeholder={startPlaceholder?.format(TIME_FORMAT) ?? (noLabel ? t('timeRegistration.field.startedAt.label') : undefined)}
                    target={timeRegistration}
                    name="startedAt"
                    onChange={this.setStartedAt}
                    autoComplete="off"
                />
                <TargetTimePicker noPopup
                    noLabel={noLabel}
                    placeholder={endPlaceholder?.format(TIME_FORMAT) ?? (noLabel ? t('timeRegistration.field.endedAt.label') : undefined)}
                    target={timeRegistration}
                    name="endedAt"
                    dateLib="moment"
                    afterChange={this.autosave}
                    autoComplete="off"
                />
                {children}
            </>
        );

        if (group) {
            fields = (
                <Form.Group>{fields}</Form.Group>
            );
        }

        return (
            <Form>{fields}</Form>
        );
    }
}

function getMinEndedAt(now) {
    // We use 4 at night instead of 12 since its more common to work past 12 than it is to start before 4.
    // So this will give the expected behaviour more often.
    const minEndedAt = now.clone().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
    // Make sure that between 12 and 4 it refers to 4 the previous day
    if (minEndedAt.isAfter(now)) {
        minEndedAt.subtract(1, 'd');
    }
    return minEndedAt;
}

@observer
export class CurrentTimeRegistrationForm extends Component {
    static propTypes = {
        open: PropTypes.bool,
    };
    static defaultProps = {
        open: true,
    };

    constructor(...args) {
        super(...args);
        this.formRef = this.formRef.bind(this);
        this.startStop = this.startStop.bind(this);
    }

    form = null;

    formRef(node) {
        this.form = node;
    }

    async startStop() {
        const { currentUser, currentTimeRegistration } = window.viewStore;

        if (currentTimeRegistration.isNew && !currentTimeRegistration.startedAt) {
            // Handle new time registration.
            this.form.setStartedAt(this.startPlaceholder);
        } else if (!!currentTimeRegistration.startedAt && !currentTimeRegistration.endedAt) {
            // Handle open currentTimeRegistration.
            currentTimeRegistration.setInput('endedAt', this.endPlaceholder);
            await this.form.save();
            if (window.viewStore.currentUser.autoClearTimeRegistration) {
                runInAction(() => {
                    currentTimeRegistration.clear();
                    currentTimeRegistration.user.parse(currentUser.toJS());
                });
            }
        } else {
            runInAction(() => {
                currentTimeRegistration.clear();
                currentTimeRegistration.user.parse(currentUser.toJS());
            });

            const form = ReactDOM.findDOMNode(this.form);
            const projectsOrTicketsInput = form.querySelector('[name="projectsOrTickets"] input');
            setTimeout(() => projectsOrTicketsInput.select(), 0);
        }
    }

    @action onOpen() {
        window.viewStore.activeCurrentTimeRegistrationForms++;
    }

    @action onClose() {
        const timeRegistration = window.viewStore.currentTimeRegistration;
        if (--window.viewStore.activeCurrentTimeRegistrationForms === 0 && timeRegistration.endedAt !== null) {
            timeRegistration.clear();
            timeRegistration.user.parse(window.viewStore.currentUser.toJS());
        }
    }

    @observable minEndedAt = getMinEndedAt(window.viewStore.currentMinute);

    componentDidMount() {
        this.openReaction = reaction(
            () => this.props.open,
            (open) => {
                if (open) {
                    this.onOpen();
                } else {
                    this.onClose();
                }
            },
        );
        this.lastEndedAtReaction = reaction(
            () => window.viewStore.currentMinute,
            (now) => {
                const minEndedAt = getMinEndedAt(now);
                if (!this.minEndedAt.isSame(minEndedAt)) {
                    this.minEndedAt = minEndedAt;
                }
            },
            { fireImmediately: true },
        );

        if (this.props.open) {
            this.onOpen();
        }
    }

    componentWillUnmount() {
        this.openReaction();
        this.lastEndedAtReaction();

        if (this.props.open) {
            this.onClose();
        }
    }

    @computed get startPlaceholder() {
        if (window.viewStore.lastTimeRegistration.endedAt?.isSameOrAfter(this.minEndedAt)) {
            return window.viewStore.lastTimeRegistration.endedAt;
        } else {
            return window.viewStore.currentMinute;
        }
    }

    @computed get endPlaceholder() {
        if (window.viewStore.currentTimeRegistration.startedAt === null) {
            return null;
        } else {
            return window.viewStore.currentMinute;
        }
    }

    render() {
        const timeRegistration = window.viewStore.currentTimeRegistration;

        let icon, color;
        if (timeRegistration.startedAt === null) {
            icon = 'play';
            color = 'green';
        } else if (timeRegistration.endedAt === null) {
            icon = 'stop';
            color = 'red';
        } else {
            icon = 'level down rotated clockwise';
            color = 'grey';
        }

        return (
            <TimeRegistrationForm autosave group
                timeRegistration={timeRegistration}
                ref={this.formRef}
                startPlaceholder={this.startPlaceholder}
                endPlaceholder={this.endPlaceholder}
                {...this.props}
            >
                <CenterIconButton data-test-start-stop
                    type="submit"
                    loading={timeRegistration.isLoading}
                    icon={icon}
                    color={color}
                    onClick={this.startStop}
                />
            </TimeRegistrationForm>
        );
    }
}
