import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Ticket } from './Ticket';

export class TicketRelation extends Model {
    static backendResourceName = 'ticket_relation';

    @observable id = null;

    relations() {
        return {
            'parentTicket': Ticket,
            'childTicket': Ticket,
        };
    }
}

export class TicketRelationStore extends Store {
    Model = TicketRelation;
    static backendResourceName = 'ticket_relation';
}
