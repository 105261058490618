import React from 'react';

// Hacky helper to ease working with notifications.
let viewStore = null;

export function configureCurrentUser(givenViewStore) {
    viewStore = givenViewStore;
}

export function getCurrentUser() {
    return viewStore.currentUser;
}

/**
 * Decorator to add currentUser to props. Example:
 *
 * @currentUser
 * @oberver
 * class TruckSendBulkMessageModal extenda Component {
 *     static propTypes = {
 *         currentUser: PropTypes.instanceOf(User).isRequired,
 *     }
 * }
 */
export function currentUser(Comp) {
    return React.forwardRef((props, ref) => <Comp {...props} ref={ref} currentUser={viewStore.currentUser} />);
}
