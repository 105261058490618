import { ModelData } from 'mobx-spine';
import { Model } from '../../../store/Base';
import { observable } from 'mobx';
import { NoteStore } from './Note';


export class Thread extends Model implements ModelData {
    static backendResourceName = 'thread';

    @observable id: number | null = null;

    @observable notes = this.relation(NoteStore);
}
