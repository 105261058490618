import { Model, Store } from 'store/Base';
import {  computed, observable } from 'mobx';
import { Team } from './Team';
import { MilestoneStore } from './Milestone';
import { ModuleOrigin, ModuleOriginStore } from './ModuleOrigin';
import { Company } from './Company';
export class Module extends Model {
    static backendResourceName = 'module';

    @observable id = null;

    @observable logo = null;

    @observable name = '';

    @observable team = this.relation(Team);

    @observable roadmap = {}

    @observable callSign = '';

    @observable description = '';

    @observable milestones = this.relation(MilestoneStore);

    @observable origin = this.relation(ModuleOriginStore)


    // Adapter to make the m2m field multiselectable. There should be a more easy way to do this
    @computed
    get originMultiSelectAdapter(): Company[] {
        return this.origin.map(origin => origin.origin)
    }

    set originMultiSelectAdapter(companies: Company[]) {
        const oldCompanies = this.originMultiSelectAdapter;
        const oldCompanyIds: (number | null)[] = oldCompanies.map(company => company.id)
        const newCompanyIds: (number | null)[] = [];

        companies.forEach(
            company => {
                newCompanyIds.push(company.id)
                if (oldCompanyIds.includes(company.id)) {
                    return;
                }
                this.origin.add({
                    module: this.toJS(),
                    origin: company.toJS()
                })
            }
        )

        const companyIdsToDelete = oldCompanyIds.filter(id => !newCompanyIds.includes(id))

        const originsToDelete: ModuleOrigin[] = companyIdsToDelete.map(companyId => {
            // Find the origin with this id
            return this.origin.find(origin => origin.origin.id === companyId)
        })

        this.origin.remove(originsToDelete)
    }

}

export class ModuleStore extends Store {
    Model = Module;
    static backendResourceName = 'module';
}
