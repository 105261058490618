import { Model, Store, OrderedStore } from 'store/Base';
import { observable } from 'mobx';
import { Ticket } from './Ticket';
import { Contact } from './Contact';
import { Casts } from './Base';
import { Project } from './Project';
import { Team } from './Team';
import { Thread } from 'module/notes/store/Thread';
import { Module } from './Module';


export const MILESTONE_STATUS_NOT_STARTED = 'not_started'
export const MILESTONE_STATUS_WIP = 'wip'
export const MILESTONE_STATUS_DONE = 'completed'

export const MILESTONE_STATUSES = [
    MILESTONE_STATUS_NOT_STARTED,
    MILESTONE_STATUS_WIP,
    MILESTONE_STATUS_DONE,
];


export class Milestone extends Model {
    static backendResourceName = 'milestone';

    @observable id = null;
    @observable dueAt = null;
    @observable name = '';
    @observable ordering = 0;

    @observable flagged = false;

    @observable onHold = false;

    // If the milestone is on a roadmap, when should it be taken on (0 = now, 10 => Far future)
    @observable roadmapTime  = 0;
    @observable roadmapWidth = 0;
    @observable status = 'not started';
    @observable deliveredBy = this.relation(Contact)
    @observable receivedBy = this.relation(Contact)
    @observable team = this.relation(Team)
    @observable project = this.relation(Project)
    @observable flaggedNoteThread = this.relation(Thread)
    @observable onHoldNoteThread = this.relation(Thread)
    @observable commentNoteThread = this.relation(Thread);

    @observable module = this.relation(Module)
    @observable parent = this.relation(Milestone)
    @observable ticket = this.relation(Ticket)


    casts() {
        return {
            dueAt: Casts.nullableDatetime,
        };
    }
}

export class MilestoneStore extends Store {
    Model = Milestone;
    static backendResourceName = 'milestone';
}

export const OrderedMilestoneStore = OrderedStore(MilestoneStore, 'ordering');
