import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Company } from './Company';


export class Contact extends Model {
    static backendResourceName = 'contact';

    static omitFields = ['full_name']

    @observable id = null;
    @observable firstName = '';
    @observable lastName = '';

    @observable fullName = '';

    @observable phabricatorId = '';

    relations() {
        return {
            company: Company
        }
    }

}

export class ContactStore extends Store {
    Model = Contact;
    static backendResourceName = 'contact';
}
