import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Module } from './Module';
import { Company } from './Company';

export class ModuleOrigin extends Model {
    static backendResourceName = 'module_origin';

    @observable id = null;

    @observable module = this.relation(Module);

    @observable origin = this.relation(Company);

}

export class ModuleOriginStore extends Store {
    Model = ModuleOrigin;
    static backendResourceName = 'module_origin';
}
