import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Company } from './Company';


export class Team extends Model {
    static backendResourceName = 'team';

    @observable id = null;
    @observable name = '';
    relations() {
        return {
            company: Company
        }
    }


}

export class TeamStore extends Store {
    Model = Team;
    static backendResourceName = 'team';
}
